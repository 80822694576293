import "./Auctions.css";
import "../user/viewauctions.css";

import React, { useState } from "react";

import abiADMIN from "../chaininfo/adminabi.json";
import { contractADMIN, NETWORK, NETWORKNAME } from "../chaininfo/contracts";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import axios from "axios";

import Countdown, { zeroPad } from "react-countdown";

const renderer = ({ days, hours, minutes, seconds }) => (
  <span>
    {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);

export default function Claim() {
  const [address, setAddress] = useState(false);
  const [auctions, setAuctions] = useState([]);

  const connect = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    m = String(m[0]).toLowerCase();

    const { chainId } = await provider.getNetwork();
    if (chainId != NETWORK) {
      toast.error("Select " + NETWORKNAME + " to continue");
      return;
    }

    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);
    let o = String(await ct.owner()).toLowerCase();

    // if (o != m) {
    //   alert("Not admin wallet!!");
    //   window.location = "/";
    //   return;
    // }
    // setAddress(m.substring(0, 6) + "..." + m.substring(m.length, m.length - 6));
    setAddress(m);
    loadAuctions();
  };

  async function loadAuctions() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];
    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);

    let c = await ct.getcUser();
    let as = [];
    for (let i = 0; i < c.length; i++) {
      let a = await ct.Auctions(c[i]);

      if (m.toLowerCase() == a.highestBidder.toLowerCase()) {
        let u = await ct.tokenURI(c[i]);
        let { data } = await axios.get(u);
        as.push({
          nftId: parseFloat(a.nftId, 16),
          expiresAt: parseFloat(a.expiresAt, 16),
          highestBid: parseFloat(a.highestBid, 16) / 10 ** 18,
          highestBidder: a.highestBidder,
          claimed: a.claimed,
          creator: a.creator,
          name: data.name,
          description: data.description,
          image: data.image,
        });
      }
      console.log("Done " + a.nftId);
    }
    // setAuctions(as);
    loadAuctionsAdmin(as);
  }

  async function loadAuctionsAdmin(as) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];
    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);

    let c = await ct.getcAdmin();
    // let as = [];
    for (let i = 0; i < c.length; i++) {
      let a = await ct.Auctions(c[i]);

      if (m.toLowerCase() == a.highestBidder.toLowerCase()) {
        let u = await ct.tokenURI(c[i]);
        let { data } = await axios.get(u);
        as.push({
          nftId: parseFloat(a.nftId, 16),
          expiresAt: parseFloat(a.expiresAt, 16),
          highestBid: parseFloat(a.highestBid, 16) / 10 ** 18,
          highestBidder: a.highestBidder,
          claimed: a.claimed,
          creator: a.creator,
          name: data.name,
          description: data.description,
          image: data.image,
        });
      }
      console.log("Done " + a.nftId);
    }
    setAuctions(as);
  }

  const settleAuction = async (nftId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];
    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);

    await toast
      .promise(
        async () => {
          await ct.settleAuction(nftId);
        },
        {
          pending: "Waiting Confirmation on blockchain!!",
          success: "Success!!",
          error: "Failed!!",
        }
      )
      .then(async () => {
        // let p = preview;
        // p.id = await ct.methods.totalSupply().call();
      });
  };

  return address ? (
    <div className="container-fluid adminbg main pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="py-3 text-white">
              Claim Your NFT &nbsp;&nbsp;&nbsp;
              <button
                className="btn adminbtn"
                onClick={() => {
                  loadAuctions();
                }}
              >
                Refresh
              </button>
            </h3>
          </div>
        </div>
        <div className="row">
          {auctions.map((a, i) => {
            return (
              <div key={a.nftId} className="col-md-3">
                <div className="box">
                  <img className="thumb" src={a.image} />
                  <small className="date">
                    <i className="fa fa-clock" />
                    &nbsp;
                    <Countdown
                      date={new Date(a.expiresAt * 1000)}
                      renderer={renderer}
                    />
                  </small>
                  <div className="row align-items-start">
                    <div className="col-8 pr-0 titlebox">
                      <h6>{a.name}</h6>
                    </div>
                  </div>
                  <hr />
                  <small className="desc">{a.description}</small>
                  <hr />
                  <div className="row align-items-start">
                    <div className="col-8">
                      <small className="label desc d-block">Address</small>

                      <small className="price">
                        {a.highestBidder.substring(0, 6)}...
                        {a.highestBidder.substring(
                          a.highestBidder.length - 5,
                          a.highestBidder.length
                        )}
                      </small>
                    </div>
                    <div className="col-4 pricebox">
                      <small className="label desc d-block">
                        Current Price
                      </small>
                      <img src="/imgs/bnb.png" className="bnbcoin" />
                      <h6 className="price">{a.highestBid}</h6>
                    </div>
                  </div>
                  <a
                    className="btn btn-theme w-100 mt-3 mx-auto d-block"
                    href={"/auction?id=" + a.nftId}
                  >
                    Details
                  </a>
                  <a
                    className="btn btn-theme w-100 mt-3 mx-auto d-block"
                    onClick={() => settleAuction(a.nftId)}
                  >
                    CLAIM
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12 adminbg text-center fullheight">
      <button className="btn adminbtn my-5" onClick={() => connect()}>
        CONNECT WALLET
      </button>
    </div>
  );
}
