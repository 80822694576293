// import "./Auctions.css";
import "./viewauctions.css";

import React, { useEffect } from "react";

import abiADMIN from "../chaininfo/adminabi.json";
import { contractADMIN, PROVIDER } from "../chaininfo/contracts";

import { ethers } from "ethers";

import axios from "axios";

import { doc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

export default function Initializefirebase() {
  async function initializefirebase() {
    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);

    let c = await ct.getcAdmin();
    // let c = await ct.getcUser();

    let p = c.length - 1;

    for (let i = 0; i < c.length; i++) {
      let a = await ct.Auctions(c[i]);
      let u = await ct.tokenURI(c[i]);
      let { data } = await axios.get(u);
      let x = {
        nftId: parseFloat(a.nftId, 16),
        expiresAt: parseFloat(a.expiresAt, 16),
        highestBid: parseFloat(a.highestBid, 16),
        highestBidder: a.highestBidder,
        claimed: a.claimed,
        creator: a.creator,
        name: data.name,
        description: data.description,
        image: data.image,
      };

      const dbRef = doc(db, "auctions", String(c[i]));
      // const dbRef = doc(db, "userAuctions", String(c[i]));
      await setDoc(dbRef, x).then(() =>
        console.log("Done " + i + " - " + x.nftId)
      );
    }
  }

  return (
    <div className="container-fluid main pt-5">
      <button onClick={() => initializefirebase()}>start</button>
    </div>
  );
}
