import "./Auctions.css";
import "../user/viewauctions.css";
import React, { useState } from "react";
import Img17 from "../../Images/Binance.png";

import abiADMIN from "../chaininfo/adminabi.json";
import { contractADMIN, NETWORK, NETWORKNAME } from "../chaininfo/contracts";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import * as IPFS from "ipfs-core";
import axios from "axios";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

export default function CreateAuction() {
  const [address, setAddress] = useState(false);
  const [ownermint, setOwnermint] = useState("");
  const [name, setName] = useState("NAME");
  const [description, setDescription] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("/imgs/empty.png");

  const connect = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    // m = String(m[0]).toLowerCase();
    m = m[0];
    const { chainId } = await provider.getNetwork();
    if (chainId != NETWORK) {
      toast.error("Select " + NETWORKNAME + " to continue");
      return;
    }

    const signer = provider.getSigner();
    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);
    let o = String(await ct.owner()).toLowerCase();

    if (!(await ct.isApprovedForAll(m, contractADMIN))) {
      await toast.promise(ct.setApprovalForAll(contractADMIN, true), {
        pending: "One Time Approval To Create Auctions!!",
        success: "Success!!",
        error: "Failed!!",
      });
    }

    if (o == m)
      setOwnermint(
        <div className="col-12 text-center">
          <a className="btn btn-primary my- mx-auto" href="/owner-mint">
            MINT FOR OWNER
          </a>
        </div>
      );

    setAddress(m.substring(0, 6) + "..." + m.substring(m.length, m.length - 6));
  };
  const preview = async (tag) => {
    if (tag.id == "itemName") {
      setName(tag.value);
    } else if (tag.id == "itemDescription") {
      setDescription(tag.value);
    } else if (tag.id == "bid") {
      setPrice(tag.value);
    } else if (tag.id == "image") {
      setImage(URL.createObjectURL(tag.files[0]));
    }
  };

  const createAuction = async (event) => {
    event.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];
    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);

    let meta, data;
    let imgObj = document.getElementById("image").files[0];

    const ipfs = await IPFS.create();

    //handle IPFS
    await toast.promise(
      async () => {
        //pin image
        let { path } = await ipfs.add(imgObj);

        await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinByHash",
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_NFTSTORAGEAPI,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            hashToPin: path,
          }),
        });

        meta = {
          name: document.getElementById("itemName").value,
          description: document.getElementById("itemDescription").value,
          image: "https://auctions.mypinata.cloud/ipfs/" + path,
        };
        //pin metadata
        const res = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_NFTSTORAGEAPI,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            pinataContent: meta,
          }),
        });
        data = res.data;
      },
      {
        pending: "Processing Metadata",
        success: "Success!!",
        error: "Failed!!",
      }
    );

    await toast.promise(
      async () => {
        let auctionEndDate = document.getElementById("time").value;
        auctionEndDate = Math.floor(new Date(auctionEndDate).getTime() / 1000);

        await ct
          .createAuction(
            String(document.getElementById("bid").value * 10 ** 18),
            String(auctionEndDate),
            String("https://auctions.mypinata.cloud/ipfs/" + data.IpfsHash)
          )
          .then(async () => {
            let t = await ct.totalSupply();
            t++;
            let auc = {
              claimed: false,
              creator: m,
              description: meta.description,
              expiresAt: auctionEndDate,
              highestBid: document.getElementById("bid").value * 10 ** 18,
              highestBidder: "0x0000000000000000000000000000000000000000",
              image: meta.image,
              name: meta.name,
              nftId: t,
            };
            let dbRef;
            if ((await ct.owner()).toLowerCase() == m)
              dbRef = doc(db, "auctions", String(t));
            else dbRef = doc(db, "userAuctions", String(t));

            await setDoc(dbRef, auc);
          });
      },
      {
        pending: "Waiting Confirmation on blockchain!!",
        success: "Success!!",
        error: "Failed!!",
      }
    );
    ipfs.stop();
  };

  return (
    <>
      <div className="container-fluid adminbg">
        <div className="container">
          <div className="row main py-5 justify-content-center">
            {ownermint}
            {address ? (
              <>
                <div className="col-md-5 text-white">
                  <h4>Auction Details</h4>
                  <p>
                    The ITEM/NFT metadata cannot be modified once auction is
                    confirmed by the blockchain.
                  </p>
                  <form
                    onChange={(event) => preview(event.target)}
                    onSubmit={(event) => createAuction(event)}
                    className="form-contact"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  >
                    <input
                      className="form-control"
                      required
                      type="file"
                      name="image"
                      id="image"
                    />
                    <input
                      type="text"
                      required
                      name="name"
                      id="itemName"
                      placeholder="Name"
                      className="form-control"
                    />

                    <textarea
                      className="form-control"
                      name="description"
                      required
                      id="itemDescription"
                      placeholder="Description"
                    />
                    <input
                      className="form-control"
                      required
                      type="text"
                      name="bid"
                      id="bid"
                      placeholder="Minumum Bid Price"
                    />
                    <input
                      className="form-control"
                      required
                      id="time"
                      type="datetime-local"
                      name="time"
                    />
                    <button className="btn adminbtn" type="submit">
                      SUBMIT
                    </button>
                  </form>
                </div>
                <div className="col-md-4">
                  <div className="main-bg">
                    <div className="img">
                      <img className="w-100 thumb" src={image} />
                    </div>
                    <div className="content">
                      <div className="row pt-3">
                        <div className="col-6">
                          <h1 className="nf">{name}</h1>
                        </div>
                        <div className="col-6">
                          <h1 className="txti m-0">Current price</h1>
                          <img className="ico" src={Img17} />{" "}
                          <small className="text-white">{price}</small>
                        </div>
                      </div>
                      <p className="text-white">
                        <small>{description}</small>
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12 text-center">
                <button className="adminbtn btn my-5" onClick={() => connect()}>
                  CONNECT WALLET
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
