import "./viewauctions.css";
import React, { useEffect, useState } from "react";

import { getDocs, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import Img17 from "../../Images/Binance.png";

export default function Auctions_User() {
  const [auctions, setAuctions] = useState([]);

  useEffect(() => {
    loadAuctions();
  }, []);

  async function loadAuctions() {
    let dbRef = doc(db, "settings", "blocked");
    let blocked = (await getDoc(dbRef)).data();

    let d = await getDocs(collection(db, "userAuctions"));
    let a = [];

    d.forEach((doc) => {
      if (
        Date.now() < parseFloat(doc.data().expiresAt, 16) * 1000 &&
        !blocked[doc.data().nftId]
      )
        a.push({
          nftId: parseFloat(doc.data().nftId, 18),
          expiresAt: parseFloat(doc.data().expiresAt, 18),
          highestBid: parseFloat(doc.data().highestBid, 18) / 10 ** 18,
          highestBidder: doc.data().highestBidder,
          claimed: doc.data().claimed,
          creator: doc.data().creator,
          name: doc.data().name,
          description: doc.data().description.substring(0, 75),
          image: doc.data().image,
        });
    });

    setAuctions(a);
  }

  return (
    <div className="container-fluid main pt-5 darkbg">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="py-3 text-white">
              Live Community Auctions &nbsp;&nbsp;&nbsp;
            </h3>
          </div>
        </div>
        <div className="row" id="auctionsContainer">
          {auctions.map((a, i) => {
            return (
              <div className="col-md-4 my-4">
                <div className="main-bg">
                  <div className="img">
                    <img className="w-100 thumb" src={a.image} />
                  </div>
                  <div className="content">
                    <h1 className="nf pt-3">{a.name}</h1>
                    <p className="text-white">
                      <small>{a.description}</small>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h1 className="txti">Address</h1>
                      <small className="text-white">
                        {a.highestBidder.substring(0, 5) +
                          ".." +
                          a.highestBidder.substring(
                            a.highestBidder.length - 3,
                            a.highestBidder.length
                          )}
                      </small>
                    </div>
                    <div className="col-6 text-right">
                      <h1 className="txti">Current price</h1>
                      <img className="ico" src={Img17} />{" "}
                      <small className="text-white">{a.highestBid}</small>
                    </div>
                  </div>
                  <br />
                  <small className="text-white pt-4 pb-1">
                    {new Date(a.expiresAt * 1000).toUTCString()}
                  </small>
                  <div className="text-center mar pt-2">
                    <a href={"/auction?id=" + a.nftId} className="btn-5">
                      Details
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
