import "./Auctions.css";
import "../user/viewauctions.css";

import React, { useState } from "react";
import { toast } from "react-toastify";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

import { GoogleAuthProvider } from "firebase/auth";
import { getAuth, signInWithPopup, signOut } from "firebase/auth";
import { app } from "../../firebase-config";
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export default function Settings() {
  const [address, setAddress] = useState(false);
  const login = async () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        if (
          user.email == "arazxa@gmail.com" ||
          user.email == "piotrszczesny2@gmail.com"
        ) {
          setAddress(true);
          return;
        } else {
          signOut(auth)
            .then(() => {
              // Sign-out successful.
            })
            .catch((error) => {
              // An error happened.
            });
          toast.error("Invalid Login Email!!");
          return;
        }

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  async function block(e) {
    e.preventDefault();
    let v = document.getElementById("itemid").value;

    const dbRef = doc(db, "settings", "blocked");

    let d = {};
    d[v] = true;

    await updateDoc(dbRef, d)
      .then(() => {
        toast.success("Blocked Successfully!!");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  async function unblock(e) {
    e.preventDefault();
    let v = document.getElementById("itemid").value;

    const dbRef = doc(db, "settings", "blocked");

    let d = {};
    d[v] = false;

    await updateDoc(dbRef, d)
      .then(() => {
        toast.success("Un-Blocked Successfully!!");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  async function setSlides(e) {
    e.preventDefault();
    let v = document.getElementById("slideid").value;

    const dbRef = doc(db, "settings", "slides");

    let d = {};
    d["auctions"] = v;

    await updateDoc(dbRef, d)
      .then(() => {
        toast.success("Slides Updated Successfully!!");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  async function setFeatured(e) {
    e.preventDefault();
    let v = document.getElementById("featuredid").value;

    const dbRef = doc(db, "settings", "featured");

    let d = {};
    d["auction"] = v;

    await updateDoc(dbRef, d)
      .then(() => {
        toast.success("Slides Updated Successfully!!");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  async function setLatest(e) {
    e.preventDefault();
    let v = document.getElementById("latestid").value;

    const dbRef = doc(db, "settings", "latest");

    let d = {};
    d["auctions"] = v;

    await updateDoc(dbRef, d)
      .then(() => {
        toast.success("Latest ids Updated Successfully!!");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  return (
    <>
      <div className="container">
        <div className="row main py-5 justify-content-center">
          {address ? (
            <>
              <div className="col-md-5">
                <h4>Settings</h4>
                <h6>Enter an Id to Block/Unblock</h6>

                <form
                  className="form-contact"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <input
                    type="text"
                    required
                    name="nftid"
                    className="form-control"
                    id="itemid"
                    placeholder="Enter the NFT Id to block/unblock"
                  />
                  <button
                    className="btn btn-danger"
                    onClick={(event) => block(event)}
                  >
                    BLOCK
                  </button>{" "}
                  <button
                    className="btn btn-success"
                    onClick={(event) => unblock(event)}
                  >
                    UNBLOCK
                  </button>
                </form>
                <br />
                <br />
                <h6>
                  Enter Slide Ids <b>5 MAX</b>
                </h6>
                <form
                  className="form-contact"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <input
                    type="text"
                    required
                    name="nftid"
                    className="form-control"
                    id="slideid"
                    placeholder="Enter NFT Ids to set as slides"
                  />
                  <button
                    className="btn btn-success"
                    onClick={(event) => setSlides(event)}
                  >
                    UPDATE
                  </button>
                </form>
                <br />
                <br />
                <h6>Enter Featured Id</h6>
                <form
                  className="form-contact"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <input
                    type="text"
                    required
                    name="nftid"
                    className="form-control"
                    id="featuredid"
                    placeholder="Enter the NFT Id to set as featured"
                  />
                  <button
                    className="btn btn-success"
                    onClick={(event) => setFeatured(event)}
                  >
                    UPDATE
                  </button>
                </form>
                <br />
                <br />
                <h6>Enter Latest Ids</h6>
                <form
                  className="form-contact"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <input
                    type="text"
                    required
                    name="nftid"
                    className="form-control"
                    id="latestid"
                    placeholder="Enter the NFT Id to set as latest"
                  />
                  <button
                    className="btn btn-success"
                    onClick={(event) => setLatest(event)}
                  >
                    UPDATE
                  </button>
                </form>
              </div>
            </>
          ) : (
            <div className="col-12 text-center">
              <button
                className="btn btn-primary my-5"
                // onClick={() => connect()}
                onClick={() => login()}
              >
                Login with Google
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
