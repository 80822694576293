import "./Auctions.css";
import "../user/viewauctions.css";
import React, { useState } from "react";

import abiADMIN from "../chaininfo/adminabi.json";
import { contractADMIN, NETWORK, NETWORKNAME } from "../chaininfo/contracts";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import * as IPFS from "ipfs-core";
import axios from "axios";

export default function OwnerMint() {
  const [address, setAddress] = useState(false);
  const [name, setName] = useState("NAME");
  const [description, setDescription] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );
  const [image, setImage] = useState("/imgs/empty.png");

  const connect = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    m = String(m[0]).toLowerCase();
    // m = m[0];
    const { chainId } = await provider.getNetwork();
    if (chainId != NETWORK) {
      toast.error("Select " + NETWORKNAME + " to continue");
      return;
    }

    const signer = provider.getSigner();
    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);
    let o = String(await ct.owner()).toLowerCase();

    // if (!(await ct.isApprovedForAll(m, contractADMIN))) {
    //   await toast.promise(ct.setApprovalForAll(contractADMIN, true), {
    //     pending: "One Time Approval To Create Auctions!!",
    //     success: "Success!!",
    //     error: "Failed!!",
    //   });
    // }

    if (o != m) {
      alert("Not admin wallet!!");
      window.location = "/";
      return;
    }

    setAddress(m.substring(0, 6) + "..." + m.substring(m.length, m.length - 6));
  };
  const preview = async (tag) => {
    if (tag.id == "itemName") {
      setName(tag.value);
    } else if (tag.id == "itemDescription") {
      setDescription(tag.value);
    } else if (tag.id == "image") {
      setImage(URL.createObjectURL(tag.files[0]));
    }
  };

  const ownerMint = async (event) => {
    event.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];
    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);

    let meta, data;
    let imgObj = document.getElementById("image").files[0];

    //handle IPFS
    await toast.promise(
      async () => {
        const ipfs = await IPFS.create();

        //pin image
        let { path } = await ipfs.add(imgObj);
        ipfs.stop();
        await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinByHash",
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_NFTSTORAGEAPI,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            hashToPin: path,
          }),
        });

        meta = {
          name: document.getElementById("itemName").value,
          description: document.getElementById("itemDescription").value,
          image: "https://pixies.mypinata.cloud/ipfs/" + path,
        };

        //pin metadata
        const res = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_NFTSTORAGEAPI,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            pinataContent: meta,
          }),
        });
        data = res.data;
      },
      {
        pending: "Processing Metadata",
        success: "Success!!",
        error: "Failed!!",
      }
    );

    await toast.promise(
      async () => {
        await ct.ownerMint(
          String(document.getElementById("recipient_address").value),
          String("https://pixies.mypinata.cloud/ipfs/" + data.IpfsHash)
        );
      },
      {
        pending: "Waiting Confirmation on blockchain!!",
        success: "Success!!",
        error: "Failed!!",
      }
    );
  };

  return (
    <>
      <div className="container">
        <div className="row main py-5 justify-content-center">
          {address ? (
            <>
              <div className="col-md-5">
                <h4>Mint to Wallet</h4>
                <p>
                  The ITEM/NFT metadata cannot be modified once auction is
                  confirmed by the blockchain.
                </p>
                <form
                  onChange={(event) => preview(event.target)}
                  onSubmit={(event) => ownerMint(event)}
                  className="form-contact"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <input
                    className="form-control"
                    required
                    type="file"
                    name="image"
                    id="image"
                  />
                  <input
                    type="text"
                    required
                    name="name"
                    id="itemName"
                    placeholder="Name"
                    className="form-control"
                  />
                  <input
                    type="text"
                    required
                    name="address"
                    id="recipient_address"
                    placeholder="Recipient Address"
                    className="form-control"
                  />

                  <textarea
                    className="form-control"
                    name="description"
                    required
                    id="itemDescription"
                    placeholder="Description"
                  />
                  <button className="btn btn-success" type="submit">
                    SUBMIT
                  </button>
                </form>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <img className="thumb" src={image} />
                  <div className="row align-items-start">
                    <div className="col-8 pr-0 titlebox">
                      <h6>{name}</h6>
                    </div>
                    {/* <div className="col-4 pricebox">
                      <small className="label desc d-block">Bid Price</small>
                      <img src="/imgs/bnb.png" className="bnbcoin" />
                      <h6 className="price">{price}</h6>
                    </div> */}
                  </div>
                  <hr />
                  <small className="desc">{description}</small>
                  {/* <a
                    className="btn btn-theme w-100 mt-3 mx-auto d-block"
                    href={"/auction?id=" + indexforQuery[i]}
                  >
                    Details
                  </a> */}
                </div>
              </div>
            </>
          ) : (
            <div className="col-12 text-center">
              <button
                className="btn btn-primary my-5"
                onClick={() => connect()}
              >
                CONNECT WALLET
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
