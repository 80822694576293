import { useEffect, useState } from "react";
import Img17 from "../Images/Binance.png";

import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
  documentId,
} from "firebase/firestore";
import { db } from "../firebase-config";

export default function Latest() {
  const [auction, setAuction] = useState([
    {
      name: "",
      image: "",
      nftId: "",
      highestBidder: "",
      expiresAt: "",
      highestBid: "",
      description: "",
    },
    {
      name: "",
      image: "",
      nftId: "",
      expiresAt: "",
      highestBidder: "",
      description: "",
      highestBid: "",
    },
    {
      name: "",
      image: "",
      nftId: "",
      expiresAt: "",
      highestBidder: "",
      highestBid: "",
      description: "",
    },
  ]);

  const loadAuction = async () => {
    const dbRef = doc(db, "settings", "latest");

    let d = (await getDoc(dbRef)).data();
    d = d.auctions.split(",");

    let s = [];
    let coll = await getDocs(
      query(collection(db, "auctions"), where(documentId(), "in", d))
    );
    coll.forEach((c) => {
      s.push({
        name: c.data().name,
        image: c.data().image,
        id: c.data().nftId,
        highestBid: c.data().highestBid,
        highestBidder: c.data().highestBidder,
        expiresAt: c.data().expiresAt,
        nftId: c.data().nftId,
        description: c.data().description,
      });
    });

    coll = await getDocs(
      query(collection(db, "userAuctions"), where(documentId(), "in", d))
    );
    coll.forEach((c) => {
      s.push({
        name: c.data().name,
        image: c.data().image,
        id: c.data().nftId,
        highestBid: c.data().highestBid,
        highestBidder: c.data().highestBidder,
        expiresAt: c.data().expiresAt,
        nftId: c.data().nftId,
        description: c.data().description,
      });
    });

    setAuction(s);
  };

  useEffect(() => loadAuction(), []);
  return (
    <>
      <div className="row pt-5 sec3 p-0">
        <div className="col-md-4 my-4">
          <div className="main-bg">
            <div className="img">
              <img className="w-100 thumb" src={auction[0].image} />
            </div>
            <div className="content">
              <h1 className="nf pt-3">{auction[0].name}</h1>
              <p className="text-white">
                <small>{auction[0].description}</small>
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <h1 className="txti">Address</h1>
                <small className="text-white">
                  {auction[0].highestBidder.substring(0, 5) +
                    ".." +
                    auction[0].highestBidder.substring(
                      auction[0].highestBidder.length - 3,
                      auction[0].highestBidder.length
                    )}
                </small>
              </div>
              <div className="col-6">
                <h1 className="txti">Current price</h1>
                <img className="ico" src={Img17} />{" "}
                <small className="text-white">
                  {auction[0].highestBid / 1e18}
                </small>
              </div>
            </div>
            <br />
            <small className="text-white pt-4 pb-1">
              {new Date(auction[0].expiresAt * 1000).toUTCString()}
            </small>
            <div className="text-center mar pt-2">
              <a href={"/auction?id=" + auction[0].nftId} className="btn-5">
                Details
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-4">
          <div className="main-bg">
            <div className="img">
              <img className="w-100 thumb" src={auction[1].image} />
            </div>
            <div className="content">
              <h1 className="nf pt-3">{auction[1].name}</h1>
              <p className="text-white">
                <small>{auction[1].description}</small>
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <h1 className="txti">Address</h1>
                <small className="text-white">
                  {auction[1].highestBidder.substring(0, 5) +
                    ".." +
                    auction[1].highestBidder.substring(
                      auction[1].highestBidder.length - 3,
                      auction[1].highestBidder.length
                    )}
                </small>
              </div>
              <div className="col-6">
                <h1 className="txti">Current price</h1>
                <img className="ico" src={Img17} />{" "}
                <small className="text-white">
                  {auction[1].highestBid / 1e18}
                </small>
              </div>
            </div>
            <br />
            <small className="text-white pt-4 pb-1">
              {new Date(auction[1].expiresAt * 1000).toUTCString()}
            </small>
            <div className="text-center mar pt-2">
              <a href={"/auction?id=" + auction[1].nftId} className="btn-5">
                Details
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-4">
          <div className="main-bg">
            <div className="img">
              <img className="w-100 thumb" src={auction[2].image} />
            </div>
            <div className="content">
              <h1 className="nf pt-3">{auction[2].name}</h1>
              <p className="text-white">
                <small>{auction[2].description}</small>
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <h1 className="txti">Address</h1>
                <small className="text-white">
                  {auction[2].highestBidder.substring(0, 5) +
                    ".." +
                    auction[2].highestBidder.substring(
                      auction[2].highestBidder.length - 3,
                      auction[2].highestBidder.length
                    )}
                </small>
              </div>
              <div className="col-6">
                <h1 className="txti">Current price</h1>
                <img className="ico" src={Img17} />{" "}
                <small className="text-white">
                  {auction[2].highestBid / 1e18}
                </small>
              </div>
            </div>
            <br />
            <small className="text-white pt-4 pb-1">
              {new Date(auction[2].expiresAt * 1000).toUTCString()}
            </small>
            <div className="text-center mar pt-2">
              <a href={"/auction?id=" + auction[2].nftId} className="btn-5">
                Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
