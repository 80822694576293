import "./Auctions.css";
import "../user/viewauctions.css";
import Img17 from "../../Images/Binance.png";

import React, { useState } from "react";

import abiADMIN from "../chaininfo/adminabi.json";
import { contractADMIN, NETWORK, NETWORKNAME } from "../chaininfo/contracts";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import axios from "axios";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

export default function UpdateAuction() {
  const [address, setAddress] = useState(false);
  const [name, setName] = useState("NAME");
  const [description, setDescription] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("/imgs/empty.png");

  const connect = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let m = await provider.send("eth_requestAccounts", []);
    m = String(m[0]).toLowerCase();

    const { chainId } = await provider.getNetwork();
    if (chainId != NETWORK) {
      toast.error("Select " + NETWORKNAME + " to continue");
      return;
    }

    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);
    // let o = String(await ct.owner()).toLowerCase();

    // if (o != m) {
    //   alert("Not admin wallet!!");
    //   window.location = "/";
    //   return;
    // }
    setAddress(m.substring(0, 6) + "..." + m.substring(m.length, m.length - 6));
  };

  const preview = async (tag) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ct = new ethers.Contract(contractADMIN, abiADMIN, provider);

    if (tag.id == "itemid") {
      try {
        let uri = await ct.tokenURI(tag.value);
        let { data } = await axios.get(uri);
        setName(data.name);
        setDescription(data.description);
        setImage(data.image);
        let a = await ct.Auctions(tag.value);
        setPrice(parseInt(a.highestBid._hex, 16) / 10 ** 18);
      } catch (error) {}
    }
  };

  const createAuction = async (event) => {
    event.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];

    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);
    let auctionEndDate = document.getElementById("time").value;
    auctionEndDate = Math.floor(new Date(auctionEndDate).getTime() / 1000);

    await toast
      .promise(
        ct.updateAuction(
          String(document.getElementById("itemid").value),
          String(document.getElementById("bid").value * 10 ** 18),
          String(auctionEndDate)
        ),
        {
          pending: "Waiting Confirmation on blockchain!!",
          success: "Success!!",
          error: "Failed!!",
        }
      )
      .then(async () => {
        let d;
        let id = document.getElementById("itemid").value;
        let dbRef = doc(db, "auctions", String(id));
        d = await getDoc(dbRef);
        if (!d.exists()) {
          dbRef = doc(db, "userAuctions", String(id));
          d = await getDoc(dbRef);
        }
        let a = d.data();
        a.highestBid = String(document.getElementById("bid").value * 10 ** 18);
        a.highestBidder = m;
        a.expiresAt = String(auctionEndDate);
        await updateDoc(dbRef, a);
      });
  };

  return (
    <>
      <div className="container-fluid adminbg">
        <div className="container">
          <div className="row text-white main py-5 justify-content-center">
            {address ? (
              <>
                <div className="col-md-5">
                  <h4>Update Auction</h4>

                  <form
                    onChange={(event) => preview(event.target)}
                    onSubmit={(event) => createAuction(event)}
                    className="form-contact"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  >
                    <input
                      type="text"
                      required
                      name="nftid"
                      className="form-control"
                      id="itemid"
                      placeholder="NFT Id"
                    />
                    <input
                      className="form-control"
                      required
                      type="text"
                      name="bid"
                      id="bid"
                      placeholder="Minumum Bid Price"
                    />
                    <input
                      className="form-control"
                      required
                      id="time"
                      type="datetime-local"
                      name="time"
                    />
                    <button className="btn btn-success" type="submit">
                      SUBMIT
                    </button>
                  </form>
                </div>
                <div className="col-md-4">
                  <div className="main-bg">
                    <div className="img">
                      <img className="w-100 thumb" src={image} />
                    </div>
                    <div className="content">
                      <div className="row pt-3">
                        <div className="col-6">
                          <h1 className="nf">{name}</h1>
                        </div>
                        <div className="col-6">
                          <h1 className="txti m-0">Current price</h1>
                          <img className="ico" src={Img17} />{" "}
                          <small className="text-white">{price}</small>
                        </div>
                      </div>
                      <p className="text-white">
                        <small>{description}</small>
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12 text-center">
                <button className="btn adminbtn my-5" onClick={() => connect()}>
                  CONNECT WALLET
                </button>
              </div>
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
}
