import "./viewauctions.css";

import React, { useEffect, useState } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

import abiADMIN from "../chaininfo/adminabi.json";
import {
  contractADMIN,
  PROVIDER,
  NETWORK,
  NETWORKNAME,
} from "../chaininfo/contracts";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import Countdown, { zeroPad } from "react-countdown";

const renderer = ({ days, hours, minutes, seconds }) => (
  <span>
    {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);

export default function SingleAuction() {
  const [auction, setAuction] = useState({
    nftId: 1,
    expiresAt: 10000000000,
    highestBid: 1,
    highestBidder: "",
    claimed: false,
    creator: "",
    name: "",
    description: "",
    image: "./imgs/empty.png",
  });
  let user = "admin";

  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get("id");
    loadAuction(id);
  }, []);

  const loadAuction = async (id) => {
    let dbRefB = doc(db, "settings", "blocked");
    let blocked = (await getDoc(dbRefB)).data();

    if (blocked[id]) {
      alert("Auction not found");
      window.location = "/";
    }

    let d;
    let dbRef = doc(db, "auctions", String(id));
    d = await getDoc(dbRef);

    if (!d.exists()) {
      dbRef = doc(db, "userAuctions", String(id));
      d = await getDoc(dbRef);
      user = "user";
    }

    let a = d.data();

    setAuction({
      nftId: parseFloat(a.nftId, 16),
      expiresAt: parseFloat(a.expiresAt, 16),
      highestBid: parseFloat(a.highestBid, 16) / 10 ** 18,
      highestBidder: a.highestBidder,
      claimed: a.claimed,
      creator: a.creator,
      name: a.name,
      description: a.description,
      image: a.image,
    });
  };

  const placeBid = async () => {
    let bid = document.getElementById("bidPrice").value;
    if (!window.ethereum) {
      toast.error("Metamask required to Place bid");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    let m = await provider.send("eth_requestAccounts", []);
    m = m[0];

    const ct = new ethers.Contract(contractADMIN, abiADMIN, signer);

    let balance = await provider.getBalance(m);
    if (bid > balance / 10 ** 18) {
      toast.error("NOT ENOUGH BALANCE FOR THIS BID");
      return;
    }

    if (bid > auction.highestBid)
      await toast
        .promise(
          ct.makeBid(String(auction.nftId), { value: String(bid * 10 ** 18) }),
          {
            pending: "Waiting Confirmation on blockchain!!",
            success: "Success!!",
            error: "Failed!!",
          }
        )
        .then(async () => {
          let dbRef;
          let a = auction;
          a.highestBid = String(bid * 10 ** 18);
          a.highestBidder = String(m);
          if (user == "admin")
            dbRef = doc(db, "auctions", String(auction.nftId));
          else dbRef = doc(db, "userAuctions", String(auction.nftId));

          await updateDoc(dbRef, a);
        });
    else toast.error("BID MUST BE HIGHER THAN CURRENT BID");
  };

  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-6">
            <img src={auction.image} className="w-100 rounded" />
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <div className="card-header bg-transparent">
                <h2>{auction.name}</h2>
                <p>
                  {!auction.claimed ? (
                    <>
                      Creator <br />
                      <b>
                        {auction.creator.substring(0, 5) +
                          ".." +
                          auction.creator.substring(
                            auction.creator.length - 3,
                            auction.creator.length
                          )}
                      </b>
                    </>
                  ) : (
                    <>
                      Owned by
                      <br />
                      <b>
                        {auction.highestBidder.substring(0, 5) +
                          ".." +
                          auction.highestBidder.substring(
                            auction.highestBidder.length - 3,
                            auction.highestBidder.length
                          )}
                      </b>
                    </>
                  )}
                </p>
              </div>
              <div className="card-body">{auction.description}</div>
              <div className="singledate card-footer bg-transparent position-relative">
                {auction.expiresAt * 1000 < Date.now() ? (
                  <h5 className="mt-2">
                    <strong>Auction Ended</strong>
                  </h5>
                ) : (
                  <>
                    <strong>Sale ends on</strong>
                    <h6 className="m-0">
                      {new Date(Number(auction.expiresAt) * 1000).toGMTString()}
                    </h6>
                    <br />
                    <strong>Time Remaining</strong>
                    <h5>
                      <Countdown
                        date={new Date(Number(auction.expiresAt) * 1000)}
                        renderer={renderer}
                      />
                    </h5>
                  </>
                )}
              </div>
              {auction.expiresAt * 1000 > Date.now() ? (
                <div className="card-footer bg-transparent">
                  <h5>
                    <h6 className="m-0 d-inline">
                      <strong>Current Bid:</strong>
                    </h6>
                    <span>
                      <img src="./imgs/bnb.png" className="bnbicon" />
                      <strong>{auction.highestBid}</strong>
                    </span>
                  </h5>
                  <h5>
                    <h6 className="m-0 d-inline">
                      Bid by:{" "}
                      <strong>
                        {auction.highestBidder.substring(0, 5) +
                          ".." +
                          auction.highestBidder.substring(
                            auction.highestBidder.length - 3,
                            auction.highestBidder.length
                          )}
                      </strong>
                    </h6>
                  </h5>
                  <hr />
                  <input
                    className="form-control"
                    placeholder="Your Bid"
                    id="bidPrice"
                  />
                  <button
                    className="btn btn-theme px-3 py-2"
                    onClick={() => placeBid()}
                  >
                    Place Bid
                  </button>
                </div>
              ) : (
                <div className="card-footer bg-transparent">
                  <h5>
                    <h6 className="m-0 d-inline">
                      <strong>Final Bid:</strong>
                    </h6>
                    <span>
                      <img src="./imgs/bnb.png" className="bnbicon" />
                      <strong>{auction.highestBid}</strong>
                    </span>
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
