import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import CreateAuction from "./Components/admin/createauction";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UpdateAuction from "./Components/admin/updateauction";
import ViewAuctions from "./Components/user/viewauctions";
import Auctions_User from "./Components/user/viewauctions_user";
import Header from "./Components/header";
import Home from "./Components/Home";
import PastAuctions from "./Components/user/pastauctions";
import PastAuctions_User from "./Components/user/pastauctions_user";
import SingleAuction from "./Components/user/singleAuction";
import Claim from "./Components/admin/claim";
import OwnerMint from "./Components/admin/ownerMint";
import Settings from "./Components/admin/settings";
import HomeNew2 from "./Components/Home new2";
import Initializefirebase from "./Components/user/initialize";

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route exact path="/init" element={<Initializefirebase />} />
          <Route exact path="/" element={<HomeNew2 />} />
          <Route exact path="/createauction" element={<CreateAuction />} />
          <Route exact path="/updateauction" element={<UpdateAuction />} />
          <Route exact path="/auction" element={<SingleAuction />} />
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route
            exact
            path="/view-auctions"
            element={
              <>
                <ViewAuctions />
              </>
            }
          />
          <Route
            exact
            path="/past-auctions"
            element={
              <>
                <PastAuctions />
              </>
            }
          />
          <Route
            exact
            path="/community-auctions"
            element={
              <>
                <Auctions_User />
              </>
            }
          />
          <Route
            exact
            path="/past-community-auctions"
            element={<PastAuctions_User />}
          />
          <Route exact path="/owner-mint" element={<OwnerMint />} />
          <Route exact path="/claim" element={<Claim />} />
          <Route exact path="/settings" element={<Settings />} />
        </Routes>
      </BrowserRouter>
      <div className="footer Backgroundnav">
        <div className="container">
          <div className="row pt-3">
            <div className="col-md-12 text-center">
              <span className="text-white">
                for support, please get in touch with us at{" "}
                <a
                  className="text-white"
                  href="mailto:support@nftartlink.com"
                  target="_blank"
                >
                  support@nftartlink.com
                </a>
              </span>
              <br />
              <br />
              <a href="https://medium.com/@NFTArtLink" target="_blank">
                <i className="iii px-3 fa-brands fa-medium"></i>
              </a>

              <a href="https://twitter.com/CargoLinkCLX" target="_blank">
                <i className="iii px-3 fa-brands fa-twitter"></i>
              </a>

              <a href="https://twitter.com/NftArtLink" target="_blank">
                <i className="iii px-3 fa-brands fa-twitter"></i>
              </a>

              <a href="https://t.me/CargoLinkOfficial" target="_blank">
                <i className="iii px-3 fa-brands fa-telegram"></i>
              </a>

              <a href="https://discord.com/invite/NUMQ7wsjaK" target="_blank">
                <i className="iii px-3 fa-brands fa-discord"></i>
              </a>

              <a
                href="https://www.facebook.com/CargoLinkOfficial/"
                target="_blank"
              >
                <i className="iii px-3 fa-brands fa-facebook"></i>
              </a>

              <a
                href="https://instagram.com/nftartlink?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <i className="iii px-3 fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UC3zOahvhdAZVrYt6sY5Ilzg"
                target="_blank"
              >
                <i className="iii px-3 fa-brands fa-youtube"></i>
              </a>

              <p className="copy pt-3 text-white">
                © 2022 NFT ART LINK. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar theme="colored" />
    </>
  );
}

export default App;
